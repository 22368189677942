<template>
  <img align="left" :src="url" class="image" @error="onLoadImgError" />
</template>


<script >
import apiService from '@/services/apiService'
import fileHelpers from '@/helpers/fileHelpers'
export default {
  // @Prop({ default: 'bsc' }) chain!:
  props: {
    avatar: String,
  },
  data() {
    return {
      url: '',
    }
  },
  methods: {
    async updateImage() {
      try {
        const val = this.avatar
        this.url = require(`../assets/logo.svg`)
        if (val instanceof File) {
          this.url = URL.createObjectURL(val)
        } else if (typeof val === 'string') {
          if (val && val.toLowerCase().startsWith('http')) {
            this.url = val
          } else if (val) {
            const model = await apiService.getFile(val)
            this.url = fileHelpers.getApiFileUrl(model)
          }
        } else if (val) {
          this.url = fileHelpers.getApiFileUrl(val)
        }
      } catch (error) {
        console.error('onAvatarChanged', error)
      }
    },
    onLoadImgError() {
      this.url = require(`../assets/logo.svg`)
    },
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    async handler(avatar) {
      await this.updateImage()
    },
    immediate: true,
  },
}
</script>

<style scoped></style>
