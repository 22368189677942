import Axios from 'axios'

const axios = Axios.create({ baseURL: process.env.VUE_APP_API_ENDPOINT_AWS })

export class ApiService {
  async getFile(id) {
    const res = await axios.get(`upload/files/${id}`)
    return res.data
  }
}

export const apiService = new ApiService()
